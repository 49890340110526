import moment from 'moment';
import TimeField from 'react-simple-timefield';
import React, { useState } from 'react';
import He from 'he';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Typography, Input, Button, Form, Tooltip, Modal, Popconfirm, Space, Divider, DatePicker, Select, Row, Checkbox,Popover, Badge, notification, Col, Menu, Dropdown, Empty, Table } from 'antd';
import Icon, { MenuOutlined, CheckSquareOutlined, PlusOutlined, ClearOutlined, ClockCircleOutlined, UserOutlined, CheckCircleOutlined, PushpinOutlined, InfoOutlined, DeleteOutlined, SearchOutlined, QuestionCircleOutlined, CheckCircleFilled, ExclamationOutlined, FieldTimeOutlined, RollbackOutlined, CrownOutlined, CheckOutlined, FormatPainterOutlined } from '@ant-design/icons';

const TaskUp = () => (
  <svg viewBox="64 64 896 896" focusable="false" data-icon="pushpin" width="1em" height="1em" fill="currentColor" aria-hidden="true" >
    <path d="M878.3,392.1L631.9,145.7c-6.5-6.5-15-9.7-23.5-9.7s-17,3.2-23.5,9.7L423.8,306.9c-12.2-1.4-24.5-2-36.8-2
        c-73.2,0-146.4,24.1-206.5,72.3c-14.3,11.5-16.6,32.4-5.1,46.7c0.8,0.9,1.6,1.8,2.4,2.7l181.7,181.7L144.1,823.5
        c-2.6,2.6-4.3,6.1-4.6,9.8l-3.4,37.2c-0.9,9.4,6.6,17.4,15.9,17.4c0.5,0,1,0,1.5-0.1l37.2-3.4c3.7-0.3,7.2-2,9.8-4.6l215.4-215.4
        l181.7,181.7c6.5,6.5,15,9.7,23.5,9.7c9.7,0,19.3-4.2,25.9-12.4c56.3-70.3,79.7-158.3,70.2-243.4l161.1-161.1
        C891.2,426.1,891.2,405.1,878.3,392.1L878.3,392.1z"/>
  </svg>
);

const TaskUpIcon = props => <Icon component={TaskUp} {...props} />;

const { Text, Paragraph, Link } = Typography;
const { Option, OptGroup } = Select;
const { TextArea } = Input;

moment.locale('ru');

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const Global = props => {

  const SortableItem = SortableElement(({value, newindex}) => 
    <div className="listnofull margintopbottom" >
      <div>
        <DragHandle />
      </div>
      <div>
        <Form.Item name={`tsk-cb-${value.id}`} >
          <Checkbox.Group>
            <Checkbox value="1"></Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </div>
      <div>
        <Form.Item style={{width:'100%'}} name={`tsk-text-${value.id}`} >
          <Input data-sort={newindex} style={{width:'100%'}} placeholder="Новый пункт" bordered={false} prefix={
            'temp' in value ? (
                <Tooltip title={`Перед тем как взять пункт в работу, необходимо обновить задачу`}>
                  <Link key="a1"><QuestionCircleOutlined /></Link>
                </Tooltip>
            ) : (
              value.job === 1 ? (
                <Tooltip title={`Отказаться от работы`}>
                  <Link onClick={() => listitemjob(newindex)} key="a1"><CheckCircleOutlined style={{color:'#26e326'}} /></Link>
                </Tooltip>
              ) : (
                <Tooltip title={`Взять в работу`}>
                  <Link onClick={() => listitemjob(newindex)} key="a1"><ClockCircleOutlined style={{color:'#e32626'}} /></Link>
                </Tooltip>
              )
            )
          } suffix={
            <Tooltip title={`Удалить`}>
              <Link type="danger" onClick={() => listitemdel(newindex)} key="a1"><DeleteOutlined /></Link>
            </Tooltip>
          }
          />
        </Form.Item>
      </div>
    </div>
  );

  const SortableList = SortableContainer(({items}) => {
    return (
      <div className="listfull">
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} newindex={index} value={value} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    let proj3= props.state.proj3;
    proj3[5] = arrayMove(proj3[5], oldIndex, newIndex);
    props.setState(({proj3}) => ({
      proj3: proj3,
    }));
  };
  
  const [form] = Form.useForm();
  if (props.state.modal === 'tasksupd') {
    //console.log(moment(fulltime(props.state.proj3[3]['notif']), "HH:mm"));
    form.setFieldsValue({ private:He.decode(props.state.proj3[3]['private']),theme:He.decode(props.state.proj3[3]['theme']),text:He.decode(props.state.proj3[3]['text']),users:props.state.proj3[3]['users'].split(',') });
    if (Number(props.state.proj3[3]['notif']) > 0) {
      form.setFieldsValue({ notif:moment(fulltime(props.state.proj3[3]['notif']), "DD.MM.YYYY"),notift:moment(fulltime(props.state.proj3[3]['notif']), "DD.MM.YYYY HH:mm").format('HH:mm') });
    }
    if (props.state.proj3[3]['groups'] !== '') {
      form.setFieldsValue({ groups:props.state.proj3[3]['groups'].split(',') });
  } 
  }

  const [form2] = Form.useForm();
  form2.setFieldsValue({ search: props.state.search });

  const [form3] = Form.useForm();

  //* Выбор задачи для обновления
  async function taskupd(v) {
    var proj3 = props.state.proj3;
    proj3[3] = v;
    if (v['lfull'] instanceof Array ) {
      proj3[5] = v['lfull'];
      v['lfull'].map(list => {
        return form.setFieldsValue({ [`tsk-cb-${list.id}`]:[list.act], [`tsk-text-${list.id}`]:list.text }); 
      })
    } else {
      proj3[5] = [];
    }

    await props.setState( { proj3:proj3 } );
  }

  const menu = (task) => (
    <Menu>
      <Menu.Item key="1"><div onClick={() => {taskupd(task); props.modal('tasksupd');}}><InfoOutlined /> Информация</div></Menu.Item>
      <Menu.Item>
        <span onClick={() => props.api({ 'method':'tasks_push', 'taskid':`${task.id}` },false)}><PushpinOutlined /> {Number(task.push) ? 'Открепить':'Закрепить'}</span>
      </Menu.Item>
      {(task.author === props.state.da.id || props.state.da.type === '1' || props.state.da.type === '999') &&
        <Menu.Item danger key="1">
          <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'tasks_del', 'taskid':`${task.id}` },false)} title={`Вы действительно хотите удалить «${task.theme}»?`} okText="Да" cancelText="Нет">
          <DeleteOutlined /> Удалить
          </Popconfirm>
        </Menu.Item>
      }
    </Menu>
  );

  const suffix = (
    <Tooltip placement="topRight" title="Поменять тип (не реализовано)">
        <Button type="text" icon={
          <CheckSquareOutlined
            style={{
              fontSize: 16,
              color: '#c5c5c5',
            }}
          />
          } 
        />
    </Tooltip>
    
  );

  //* Отправка формы задачи
  async function tasksadd(v) {
    for( let value in v) {
      if(v[value] instanceof Array) {
        v[value] = v[value].join(',');
      }
    }
    v['notif'] = Date.parse(props.fulltime(v['notif'],v['notift']))/1000;
    if (v['text']) {
      v['text'] = v['text'].replace(/[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n. ]|^[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]+/gi, "");
    }
    
    v['theme'] = v['theme'].replace(/\s+/g,' ').replace(/[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]|^[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]+/gi, "");
    v['method'] = 'tasks_add';
    var proj3 = props.state.proj3;
    for (var z = 0; z < proj3[5].length; z++) {
      v[`mv-${proj3[5][z]['id']}`] = z+1;
    }
    proj3[5] = [];
    props.setState( {proj3:proj3,modal:''} );
    await form.resetFields();
    await props.api(v,false);
    await canc();
  }

  async function tasksupd(v) {
    for( let value in v) {
      if(v[value] instanceof Array) {
        v[value] = v[value].join(',');
        if (value.split('-')[0] === 'tsk') {

        }
      }
    }
    v['notif'] = Date.parse(props.fulltime(v['notif'],v['notift']))/1000;
    v['taskid'] = props.state.proj3[3]['id'];
    if (v['text']) {
      v['text'] = v['text'].replace(/[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n. ]|^[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]+/gi, "");
    }
    v['theme'] = v['theme'].replace(/\s+/g,' ').replace(/[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]|^[^a-zа-яЁё0-9\-_#,:;/!$%*№'()@\r\n.  ]+/gi, "");
    v['method'] = 'tasks_upd';
    var proj3 = props.state.proj3;
    for (var z = 0; z < proj3[5].length; z++) {
      v[`mv-${proj3[5][z]['id']}`] = z+1;
    }

    proj3[5] = [];
    props.setState( {proj3:proj3,modal:''} );
    await form.resetFields();
    await props.api(v,false);
    await canc();
  }

  //* Поиск задачи
  async function tasksearch(v) {
    v['method'] = 'tasks_list';
    await props.setState( { modal:'' } );
    await props.api(v,false);
  }

  //* Функция названия
  async function tasktheme (e) { 
    form.setFieldsValue({ theme: e.target.value.replace(/\s+/g,' ').replace(/[^a-zа-яЁё0-9\-_#№'(). ]|^[^a-zа-яЁё0-9#№ ]+/gi, "") });
  }

  function disabledDate(current) {
    return current && current < moment().subtract(1, 'days').endOf('day');
  }

  const notif = text => {
    notification.info({
      message: `Информация`,
      description:text,
      placement:'bottomRight',
    });
  };

  const handleVisibleChange = popovervis => {
    if (props.state.popovervis !== popovervis) {
      props.setState({ popovervis });
    } else {
      props.setState({ popovervis:'none' });
    }
  };

  //! !@#$%^&*()_+1234567890-=!"№;%:?*()_+<>?:"|{},./;'\[]

  const color = v => {
    switch(v) {
      case '1': return '#ffffff';
      case '2': return '#ffc9c5';
      case '3': return '#ffeaad';
      case '4': return '#fffbcb';
      case '5': return '#e4ffc4';
      case '6': return '#d7fff6';
      case '7': return '#d8f8ff';
      case '8': return '#c7dcff';
      case '9': return '#ead2ff';
      case '10': return '#ffe1f1';
      case '11': return '#ffedd8';
      case '12': return '#e8eaed';
      default: return '#f7f7f7';
    }
  }

  const colors = ['#f7f7f7','#ffc9c5','#ffeaad','#fffbcb','#e4ffc4','#d7fff6','#d8f8ff','#c7dcff','#ead2ff','#ffe1f1','#ffedd8','#e8eaed',];

  //* Полное время 
  function fulltime(time) {
    let date = new Date(Number(time)*1000);
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}/${d2}/${d3} ${d4}:${d5}`;
  }

  //* Разница между датами
  function datedif(time) {
    let notiftime = new Date(Number(time)*1000);
    let newdate = new Date();
    let days = Math.ceil(Math.abs(notiftime.getTime() - newdate.getTime()) / (1000 * 3600 * 24))*Math.sign(notiftime.getTime() - newdate.getTime());
    return days;
  }

  //* Вывод текста разницы дат
  function datediffull(days) {
    let end = '';
    if (days === 0) {
      end = 'Осталось меньше суток.';
    } else if (days > 0) {
      end = props.decnum( days,[ 'Остался ','Осталось ','Осталось ' ] )+days+props.decnum( days,[ ' день',' дня',' дней' ] )+'.';
    } else {
      end = 'Просрочено на '+days*-1+props.decnum( days*-1,[ ' день',' дня',' дней' ] )+'.';
    }
    return end;
  }

  //* Добавление ответа в вопрос
  async function listadd() {
    let proj3= props.state.proj3;
    let id = 1;
    let text = '';
    if(proj3[5] instanceof Array && proj3[5].length > 0) {
        id = Number(indexOfMax(proj3[5],'id'))+1;
        await proj3[5].push({
            id: id,
            text: text,
            act: 0,
            temp: 1
        });
    } else {
      proj3[5] = [];
        await proj3[5].push({
            id: id,
            text: text,
            act: 0,
            temp: 1
        });
    }
    await props.setState( { proj3:proj3 } );
  }

  function indexOfMax(arr,name) {
    if (arr.length === 0) {
        return -1;
    }

    var max = Number(arr[0][name]);
    var maxIndex = 0;

    for (var i = 1; i < arr.length; i++) {
        if (Number(arr[i][name]) > max) {
            maxIndex = i;
            max = arr[i][name];
        }
    }

    return max;
  }

  //* Удаление ответа из вопроса
  async function listitemdel(index) {
    let proj3 = props.state.proj3;
    await proj3[5].splice(index,1);
    await props.setState( { proj3:proj3 } );
  }

  //* Взять в работе
  async function listitemjob(index) {
    let proj3 = props.state.proj3;
    let v = {};
    v['method'] = 'tasks_item';
    v['taskid'] = proj3[3]['id'];
    v['itemid'] = proj3[5][index]['id'];
    props.api(v,false)
    .then(async response => {
      if ('code' in response) {
        var newjob = response.list.find(task => task.id === v['taskid'])['lfull'].find(item => item.id === v['itemid'])['job'];
        proj3 = props.state.proj3;
        proj3[5][index]['job'] = newjob;
        await props.setState({proj3:proj3});
      }
    });
    
  }

  const columns2 = [
    {
        title: '',
        render: (record,index,rowIndex) => <Checkbox data-index={rowIndex} defaultChecked={Number(record['act'])}></Checkbox>,
        width: 30,
    },{
      title: '',
      render: (record,index,rowIndex) => <Input style={{whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}} data-index={rowIndex} placeholder="Новый пункт" defaultValue={He.decode(record['text'])} bordered={false} />,
      ellipsis: true,
    }
  ];

  async function canc() {
    const v = await form2.validateFields();
    v['method'] = 'tasks_list';
    var proj3 = props.state.proj3;
    proj3[5] = [];
    props.setState( {proj3:proj3} );
    await form.resetFields();
    props.api(v,false);
  }

  return  (
      <React.Fragment>
        <Modal className="modal-mini modal_new" width={900} title="Добавление задачи" mask={false} visible={props.state.modal === 'tasksadd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {canc();props.setState( { modal:'' } );}} footer={null}>
          <div className="modal-mini-body">
            <div className="modal_newtitle">Добавление задачи</div>
            {!props.state.moreload || !props.state.loading ?
              <Form onFinish={tasksadd} form={form3} name="form1">
                <Form.Item name="theme" rules={[{ required: true, message: 'Пожалуйста, укажите заголовок' }]} suffix={suffix} >
                  <Input bordered={false} placeholder="Введите заголовок" onChange={ tasktheme } />
                </Form.Item>
                <Form.Item name="text">
                  <TextArea bordered={false} placeholder="Задача..." rows={6} />
                </Form.Item>
                {!props.state.moreload ? (
                  <React.Fragment>
                    <div className="fcenter" style={{marginBottom:'20px'}}>
                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => listadd()} />
                    </div>
                    <SortableList items={props.state.proj3[5]} onSortEnd={onSortEnd} useDragHandle axis="y" lockAxis="y"  />
                  </React.Fragment>
                ) : (
                  <Empty className="dempty" description={<Text>Нет данных</Text>} />
                )}
                <Space wrap style={{marginTop:20,width:'100%'}} className="space_input">
                  {(props.state.da.type === '1' || props.state.da.type === '999') &&
                    <Form.Item name="private">
                      <Select
                          showSearch
                          style={{ width: 130 }}
                          placeholder="Приватность"
                          optionFilterProp="children"
                        >
                        <Option key="2" value="0">Не приватная</Option>
                        <Option key="1" value="1">Приватная</Option>
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item name="users">
                    <Select
                        mode="multiple"
                        showSearch
                        maxTagCount="responsive"
                        style={{ width: 200 }}
                        placeholder="Выберите получателя"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      {props.state.proj3[2].map(user => (
                        <Option key={user.id} value={user.id}>{user.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="groups">
                    <Select
                        mode="multiple"
                        showSearch
                        maxTagCount="responsive"
                        style={{ width: 200 }}
                        placeholder="Выберите группы"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      {props.state.groups.map(group => (
                        <Option key={group.id} value={group.id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Space>
                <Space wrap style={{marginTop:20,width:'100%'}}>
                  <Form.Item name="notif">
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item name="notift">
                    <TimeField
                      colon=":"
                      input={<Input />}
                    />
                  </Form.Item>
                </Space>
                <div className="fcenter" style={{marginTop:'20px'}}>
                    <Form.Item className="bluebut">
                      <Button shape="round" htmlType="submit">Сохранить</Button>
                    </Form.Item>
                  </div>
              </Form>
            :
              <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            }
          </div>
        </Modal>
        <Modal className="modal-mini modal_new" width={900} title="Просмотр задачи" mask={false} visible={props.state.modal === 'tasksupd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {canc();props.setState( { modal:'' } )}} footer={null}>
          <div className="modal-mini-body">
            <div className="modal_newtitle">Просмотр задачи</div>
            {!props.state.moreload || !props.state.loading ?
              <Form onFinish={tasksupd} form={form} name="form2">
                <Form.Item name="theme" rules={[{ required: true, message: 'Пожалуйста, укажите заголовок' }]} suffix={suffix}>
                  <Input bordered={false} placeholder="Введите заголовок" onChange={ tasktheme } />
                </Form.Item>
                <Form.Item name="text">
                  <TextArea bordered={false} placeholder="Задача..." rows={6} />
                </Form.Item>
                {!props.state.moreload ? (
                  <React.Fragment>
                    <div className="fcenter" style={{marginBottom:'20px'}}>
                        <Button className="des-button" type="dashed" shape="circle" icon={<PlusOutlined />} onClick={() => listadd()} />
                    </div>
                    <SortableList items={props.state.proj3[5]} onSortEnd={onSortEnd} useDragHandle axis="y" lockAxis="y"  />
                  </React.Fragment>
                ) : (
                  <Empty className="dempty" description={<Text>Нет данных</Text>} />
                )}
                <Space wrap style={{marginTop:20,width:'100%'}} className="space_input">
                  {(props.state.da.type === '1' || props.state.da.type === '999') &&
                    <Form.Item name="private">
                      <Select
                          showSearch
                          style={{ width: 130 }}
                          placeholder="Приватность"
                          optionFilterProp="children"
                        >
                        <Option key="2" value="0">Не приватная</Option>
                        <Option key="1" value="1">Приватная</Option>
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item name="users">
                    <Select
                        mode="multiple"
                        showSearch
                        maxTagCount="responsive"
                        style={{ width: 200 }}
                        placeholder="Выберите получателя"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      {props.state.proj3[2].map(user => (
                        <Option key={user.id} value={user.id}>{user.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="groups">
                    <Select
                        mode="multiple"
                        showSearch
                        maxTagCount="responsive"
                        style={{ width: 200 }}
                        placeholder="Выберите группы"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                      {props.state.groups.map(group => (
                        <Option key={group.id} value={group.id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Space>
                <Space wrap style={{marginTop:20}}>
                  <Form.Item name="notif">
                    <DatePicker
                      format="DD.MM.YYYY"
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                  <Form.Item name="notift">
                    <TimeField
                      colon=":"
                      input={<Input />}
                    />
                  </Form.Item>
                </Space>
                <div className="fcenter" style={{marginTop:'20px'}}>
                  <Form.Item className="bluebut">
                    <Button shape="round" htmlType="submit">Сохранить</Button>
                  </Form.Item>
                </div>
              </Form>
            :
              <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            }
          </div>
        </Modal>
        <Row style={{flexDirection:'column'}} className="wowload">
          <Modal className="modal-mini modal_new" key="m1" title="Фильтры" mask={false} visible={props.state.modal === 'filters'} footer={null} closable={null} onOk={() => props.setState( { modal:'' } )} onCancel={() => props.setState( { modal:'' } )} >
            <div className="modal-mini-body">
              <div className="modal_newtitle">Фильтры</div>
              <Form name="form3" onFinish={tasksearch} form={form2} >
                <Form.Item hidden key="search" name="search">
                  <Input placeholder="Название" suffix={<SearchOutlined />} onChange={ props.onChange } />
                </Form.Item>
                <Space wrap direction="vertical" style={{width:'100%'}}>
                  {(props.state.da.type === '1' || props.state.da.type === '999') &&
                    <Form.Item key="users" name="s_users">
                      <Select
                        defaultValue=""
                        showSearch
                        placeholder="Сотрудник"
                        optionFilterProp="children"
                      >
                        <OptGroup label="Дополнительно">
                          <Option key="-2" value="">Задачи ОТ и ДЛЯ меня</Option>
                          <Option key="-1" value="all">Все задачи</Option>
                        </OptGroup>
                        <OptGroup label="Задачи сотрудника">
                          {props.state.proj3[2].map(user => (
                            <Option key={user.id} value={user.id}>{user.name}</Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item key="comp" name="s_comp">
                    <Select
                      defaultValue="0"
                      placeholder="Статус"
                    >
                      <OptGroup label="Дополнительно">
                        <Option key="1" value="">Все статусы</Option>
                      </OptGroup>
                      <OptGroup label="Основное">
                        <Option key="2" value="1">Выполнено</Option>
                        <Option key="3" value="0">Не выполнено</Option>
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item key="work" name="s_work">
                      <Select defaultValue="">
                        <OptGroup label="Дополнительно">
                          <Option key="0" value="">Все статусы</Option>
                        </OptGroup>
                        <OptGroup label="Основное">
                          <Option key="2" value="1">В работе</Option>
                          <Option key="3" value="0">Не в работе</Option>
                        </OptGroup>
                      </Select>
                    </Form.Item>
                    <Form.Item key="groups" name="s_groups">
                      <Select defaultValue="">
                        <OptGroup label="Дополнительно">
                          <Option key="0" value="">Все группы</Option>
                          <Option key="-1" value="-1">Без группы</Option>
                        </OptGroup>
                        <OptGroup label="Задачи группы">
                          {props.state.groups.map(group => (
                            <Option key={group.id} value={group.id}>{group.name}</Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                    <Form.Item key="color" name="s_color">
                      <Select defaultValue="">
                        <OptGroup label="Дополнительно">
                          <Option key="0" value="">Все цвета</Option>
                        </OptGroup>
                        <OptGroup label="Цвета">
                        {colors.map((color,index) => (
                          <Option key={index+1} value={index+1}>
                            <Space><Button style={{color:color,borderColor:Number(color) === index+1 ? '#da5100':color}} shape="circle" icon={<CheckCircleFilled />} size='small' /><Text>цвет</Text></Space>
                          </Option>
                        )
                        )}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                </Space>
                <div className="fcenter" style={{marginTop:'20px'}}>
                  <Space wrap>
                    <Form.Item className="bluebut">
                        <Button shape="round" htmlType="submit">Применить</Button>
                    </Form.Item>
                    <Form.Item>
                      <Button shape="round" onClick={() => form2.resetFields()}>Сброс</Button>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
            </div>
          </Modal>
          <div className="floattask"><span onClick={() => {props.modal('tasksadd');form3.resetFields();}}></span></div>
          <Col span={24} className="whitebut col_24" >
            <Space wrap className="mb0">
              <Form name="form3" onFinish={tasksearch} form={form2} >
                <Space wrap className="filters_center">
                  <Form.Item key="search" name="search" className="inputtrans" >
                    <Input prefix={<Button type="text" size="small" className="searchsub bnone" shape="circle" htmlType="submit" icon={<SearchOutlined style={{color:'#ffffff'}} />} />} onChange={ props.onChange } />
                  </Form.Item>
                  <Form.Item className="filtersvisible" >
                    <Button shape="round" onClick={() => {props.modal('filters');}}>Фильтр</Button>
                  </Form.Item>
                  {(props.state.da.type === '1' || props.state.da.type === '999') &&
                    <Form.Item key="users" name="s_users" className="filtershidden inputtrans">
                      <Select
                        defaultValue=""
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Сотрудник"
                        optionFilterProp="children"
                      >
                        <OptGroup label="Дополнительно">
                          <Option key="-2" value="">Задачи ОТ и ДЛЯ меня</Option>
                          <Option key="-1" value="all">Все задачи</Option>
                        </OptGroup>
                        <OptGroup label="Задачи сотрудника">
                          {props.state.proj3[2].map(user => (
                            <Option key={user.id} value={user.id}>{user.name}</Option>
                          ))}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item key="comp" name="s_comp" className="filtershidden inputtrans">
                    <Select
                      defaultValue="0"
                      style={{ width: 140 }}
                      placeholder="Статус"
                    >
                      <OptGroup label="Дополнительно">
                        <Option key="1" value="">Все статусы</Option>
                      </OptGroup>
                      <OptGroup label="Основное">
                        <Option key="2" value="1">Выполнено</Option>
                        <Option key="3" value="0">Не выполнено</Option>
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item key="work" name="s_work" className="filtershidden inputtrans">
                    <Select defaultValue="" style={{ width: 140 }}>
                      <OptGroup label="Дополнительно">
                        <Option key="0" value="">Все статусы</Option>
                      </OptGroup>
                      <OptGroup label="Основное">
                        <Option key="2" value="1">В работе</Option>
                        <Option key="3" value="0">Не в работе</Option>
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item key="groups" name="s_groups" className="filtershidden inputtrans">
                    <Select defaultValue="" style={{ width: 140 }}>
                      <OptGroup label="Дополнительно">
                        <Option key="0" value="">Все группы</Option>
                        <Option key="-1" value="-1">Без группы</Option>
                      </OptGroup>
                      <OptGroup label="Задачи группы">
                        {props.state.groups.map(group => (
                          <Option key={group.id} value={group.id}>{group.name}</Option>
                        ))}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item key="color" name="s_color" className="filtershidden inputtrans">
                    <Select defaultValue="" style={{ width: 120 }}>
                      <OptGroup label="Дополнительно">
                        <Option key="0" value="">Все цвета</Option>
                      </OptGroup>
                      <OptGroup label="Цвета">
                      {colors.map((color,index) => (
                        <Option key={index+1} value={index+1}>
                          <Space><Button style={{color:color,borderColor:Number(color) === index+1 ? '#da5100':color}} shape="circle" icon={<CheckCircleFilled />} size='default' /><Text>цвет</Text></Space>
                        </Option>
                      )
                      )}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item className="filtershidden">
                    <Tooltip placement="top" title="Сбросить">
                      <Button shape="circle" onClick={() => form2.resetFields()} icon={<ClearOutlined />} />
                    </Tooltip>
                  </Form.Item>
                  <Form.Item className="filtershidden" className="bluebut">
                    <Button shape="round" htmlType="submit">Применить</Button>
                  </Form.Item>
                </Space>
              </Form>
            </Space>
          </Col>
          {!props.state.moreload ?
            <Col span={24} style={{marginTop:20}} >
              <Row className="wrow animdown">
                {props.state.proj3[1].length === 0 &&
                  <Col style={{display:'flex',margin:'auto'}} ><Empty className="dempty" description={<Text>Нет данных</Text>} /></Col>
                }
                {props.state.proj3[1].map(task => (
                  <Dropdown overlay={menu(task)} trigger={['contextMenu']} >
                    <Col style={{background:color(task.color),order:Number(task.push) ? -1:0}} className="wcol shadowscreen taskhide" >
                      <Badge>
                        <div className="elementlock" style={{height:'100%'}} >
                          <div className="taskheader">
                            <div className="theme">
                              <span className="tasks_theme">{task.theme} </span>
                                {task.lfull instanceof Array ? (
                                  Object.keys(task.lfull).length > 0 ? (
                                    <span className="tasks_progress">
                                      {
                                        'Выполнено '+Object.keys(task.lfull.filter( function( mini ) { return( mini['act'] === "1" ); })).length+' из '+Object.keys(task.lfull).length
                                      }
                                      </span>
                                  ) : (
                                    null
                                  )
                                ) : (
                                  null
                                ) }
                            </div>
                            <Tooltip placement="top" title={Number(task.push) ? 'Открепить':'Закрепить'}>
                              <Button className="tasktop" style={Number(task.push) ? {color:'rgba(0, 0, 0, 0.85)',borderColor:'rgb(7, 96, 154, 1)',background:'rgba(255, 255, 255, 1)'} : {color:'rgba(0, 0, 0, 0.25)',borderColor:'rgba(0, 0, 0, 0.10)',background:'rgba(255, 255, 255, 1)'}} onClick={() => props.api({ 'method':'tasks_push', 'taskid':`${task.id}` },false)} shape="circle" icon={Number(task.push) ? <TaskUpIcon style={{color: '#07609a'}} />:<PushpinOutlined style={{color: '#07609a'}} />} size='middle' />
                            </Tooltip>
                          </div>
                          <Divider style={{margin:0}} />
                          {(task.text.length > 0 || "lfull" in task ) ?
                            <Paragraph onClick={() => {taskupd(task); props.modal('tasksupd');}} data-color="linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)" className={`maxtask c${task.color}`}>
                              {task.text.length > 0 &&
                                <div dangerouslySetInnerHTML={{ __html: task.text.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />
                              }
                              {"lfull" in task  &&
                                <Table className="scrolltable" showHeader={false} size="small" style={task.text.length > 0 ? {marginTop:20} : {marginTop:0}} pagination={false} locale={{ emptyText: <Empty className="dempty" description={<Text>Нет данных</Text>} />}} dataSource={"lfull" in task ? task.lfull : [] } rowKey="table1" key={new Date().getTime()+'table1'} columns={columns2}></Table>
                              }
                            </Paragraph> 
                          :
                            <Paragraph onClick={() => {taskupd(task); props.modal('tasksupd');}} className={`maxtask c${task.color}`}>
                            </Paragraph> 
                          }
                        </div>
                      </Badge>
                      <Paragraph className="lasttask">
                        <Space wrap className="space_tools">
                          {(props.state.da.id === task.author || props.state.da.type === '1' || props.state.da.type === '999' ) &&
                            <Tooltip placement="top" title={Number(task.comp) ? 'Возобновить' : 'Завершить'}>
                              <Button style={{color:'#0099cc',borderColor:'#9edef3',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" onClick={() => props.api({ 'method':'tasks_comp', 'taskid':`${task.id}` },false)} icon={Number(task.comp) ? <RollbackOutlined /> : <CheckOutlined />} size='default' />
                            </Tooltip>
                          }
                          <Popover placement="top" title="Автор" content={<React.Fragment>Автор {task.authorname}</React.Fragment>} trigger="click">
                            <Button style={{color:'#ccad00',borderColor:'#ffee90',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<CrownOutlined />} size='default' />
                          </Popover>
                          <Popover placement="top" title="Исполнитель" content={<React.Fragment>{task.usersname}</React.Fragment>} trigger="click">
                          <Button style={{color:'#44944a',borderColor:'#cdeacf',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<UserOutlined />} size='default' />
                          </Popover>
                          <Popover placement="top" title="Дата создания" content={<React.Fragment>{props.lasttime(task.date,'Неизвестно когда','full')}</React.Fragment>} trigger="click">
                            <Button style={{color:'#b582b5',borderColor:'#cdeacf',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<FieldTimeOutlined />} size='default' />
                          </Popover>
                          {(Number(task.notif) > 0 && !Number(task.comp)) &&
                            <Popover placement="top" title="Дата завершения" content={
                              <React.Fragment>
                                {props.lasttime(task.notif,'Неизвестно когда','full')}. {datediffull(datedif(task.notif))}
                              </React.Fragment>
                            } trigger="click">
                              <Badge showZero size="small" style={
                                  (datedif(task.notif) >= 0) ? datedif(task.notif) === 1 ? { backgroundColor: '#e28b00',boxShadow:'none' } : { backgroundColor: '#52c41a',boxShadow:'none' } : { boxShadow:'none' }
                                } count={
                                  (datedif(task.notif) <=7 && datedif(task.notif) >= -7) ? String(Math.abs(datedif(task.notif))) : <React.Fragment></React.Fragment>
                                }>
                                  <Button style={{color:'#db8b00',borderColor:'#ffee90',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<ExclamationOutlined />} size='default' />
                              </Badge>
                            </Popover>
                          }
                          <Tooltip placement="top" title="Изменить цвет">
                            <Popover
                              placement="bottom"
                              content={
                                <div className="colors">
                                  {colors.map((color,index) => (
                                    <div>
                                      <Button style={{color:color,borderColor:Number(task.color) === index+1 ? '#da5100':color}} onClick={() => props.api({ 'method':'tasks_col', 'color':`${index+1}`, 'taskid':`${task.id}` },false)} shape="circle" icon={<CheckCircleFilled />} size='default' />
                                    </div>
                                  ))}
                                </div>
                              }
                              trigger="click"
                              visible={props.state.popovervis === task.id}
                              onVisibleChange={() => handleVisibleChange(task.id)}
                            >
                              <Button style={{color:'rgba(0, 0, 0, 0.85)',borderColor:'#e2e2e2',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<FormatPainterOutlined />} size='default' />
                            </Popover>
                          </Tooltip>
                          {(props.state.da.id === task.author || props.state.da.type === '1' || props.state.da.type === '999' ) &&
                            <Tooltip placement="top" title="Удалить">
                              <Popconfirm placement="bottom" onConfirm={() => props.api({ 'method':'tasks_del', 'taskid':`${task.id}` },false)} title={`Вы действительно хотите удалить «${task.theme}»?`} okText="Да" cancelText="Нет">
                                <Button style={{color:'#ff4d4f',borderColor:'#ffdfe0',background:'rgba(255, 255, 255, 0.60)'}} shape="circle" icon={<DeleteOutlined />} size='default' />
                              </Popconfirm>
                            </Tooltip>
                          }
                        </Space>
                      </Paragraph>
                    </Col>
                  </Dropdown>
                ))}
              </Row>
              </Col>
            :
            <Col span={24} style={{padding:20,marginTop:20}} >
              <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            </Col>
          }
        </Row>
      </React.Fragment>
    )
};

export default Global;