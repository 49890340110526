import moment from 'moment';
import TimeField from 'react-simple-timefield';
import MaskedInput from 'antd-mask-input'
import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Empty, Tooltip, Card, Table, Divider, Form, Input, Select, Modal, Button, DatePicker, Tabs, List, Popconfirm, Alert } from 'antd';
import { StopOutlined, SearchOutlined, CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Client_View from '../../img/icons/client_view.png';

moment.locale('ru');

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Meta } = Card;
const { Option } = Select;
const { Paragraph, Link, Title, Text } = Typography;
const Global = props => {

const [form1] = Form.useForm();
const [form2] = Form.useForm();
const [form3] = Form.useForm();
const [form4] = Form.useForm();
const [form5] = Form.useForm();
const [form6] = Form.useForm();
const [form7] = Form.useForm();
const [form8] = Form.useForm();
const [form9] = Form.useForm();
const [form10] = Form.useForm();
const [form11] = Form.useForm();
const [form12] = Form.useForm();
form2.setFieldsValue({ search: props.state.search });

const componentRef = useRef();
const componentRef2 = useRef();
const componentRef3 = useRef();

let { filteredInfo } = props.state;
    filteredInfo = filteredInfo || {};
    
var obj = {
    //* Поиск
    get potentials () {
        if ( props.state.potentials.length > 0 ) {
            let search = props.state.search.toLowerCase();
            return props.state.potentials.filter( function( item ) {
                return(
                    item['phone'].toLowerCase().indexOf( search ) > -1 ||
                    item['lastname'].toLowerCase().indexOf( search ) > -1 ||
                    item['firstname'].toLowerCase().indexOf( search ) > -1 ||
                    item['patronymic'].toLowerCase().indexOf( search ) > -1
                );
            } );
        } else {
            return props.state.potentials;
        }
    }
}

const columns1 = [
    {
        title: 'Телефон',
        dataIndex: 'phone',
        key: 'phone',
        render: (text) => <Link href={`tel:+${text}`}>{`+${text}`}</Link>,
        width: 100,
    },
    {
        title: 'ФИО',
        width: 100,
        render: (record) => (`${record.lastname.length > 0 ? record.lastname:''}${record.firstname.length > 0 ? ' '+record.firstname:'' }${record.patronymic.length > 0 ? ' '+record.patronymic:''}`)
    },
    {
        title: 'Дата внесения',
        dataIndex: 'date',
        key: 'date',
        render: (text) => props.lasttime(text,'Неизвестно когда','full'),
        width: 100,
    },
    {
        title: 'Кто внёс',
        width: 100,
        dataIndex: 'doctor_name',
        key: 'doctor_name',
        filters: obj.potentials.map(function(item){return {text:item.doctor_name,value:item.doctor_name}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().map(JSON.parse),
        filteredValue: filteredInfo.doctor_name || null,
        onFilter: (value, record) => record.doctor_name.includes(value)
    }
];

    //* Отправка формы клиенты
    async function potadd(v) {
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['method'] = 'potential_add';
        v['phone'] = Number(('7'+v['phone']).replace(/\D+/g,""));
        if (String(v['phone'])[1] !== '9') {
            props.notif('error','Неверный номер','Номер должен начинаться на 79');
            return false;
        }
        if (String(v['phone']).length !== 11) {
            props.notif('error','Неверный номер','Длина номера должна быть равна 11 символам');
            return false;
        }
        await props.api(v,true);
    }

    async function potpush(id) {
        await form1.resetFields();
        await props.api({
            'method':'potential_get',
            'pcliid':id
        },false);
        await props.panel('potential_view');
    }

    //* Отправка формы клиента
    async function potupd(v) {
        v['birthday'] = Date.parse(v['birthday'])/1000;
        v['method'] = 'potential_upd';
        v['phone'] = Number(('7'+v['phone']).replace(/\D+/g,""));
        if (String(v['phone'])[1] !== '9') {
            props.notif('error','Неверный номер','Номер должен начинаться на 79');
            return false;
        }
        if (String(v['phone']).length !== 11) {
            props.notif('error','Неверный номер','Длина номера должна быть равна 11 символам');
            return false;
        }
        await props.api(v,true);
    }

    //* Вызов окна добавления
    async function potpushadd() {
        await props.modal('potential_add');
    }
    
    //* Вызов окна добавления
    async function servicepushadd() {
        await form11.resetFields();
        await props.modal('potservice_add');
    }

    //* Вызов окна обновления
    async function servicepushupd(id) {
        await form12.resetFields();
        var v = {};
        v['potserid'] = id;
        v['method'] = 'potential_service_get';
        await props.api(v,false);
    }

    //* Отправка формы
    async function serviceadd(v) {
        v['days'] = Date.parse(v['days'])/1000;
        v['date'] = Date.parse(v['date'])/1000;
        v['method'] = 'potential_service_add';
        await props.api(v,true);
    }

    //* Отправка формы
    async function serviceupd(v) {
        v['days'] = Date.parse(v['days'])/1000;
        v['date'] = Date.parse(v['date'])/1000;
        v['method'] = 'potential_service_upd';
        await props.api(v,true);
    }

    //* Полное время 
    function fulltime(time) {
        let date = new Date(Number(time)*1000);
        let d1 = (date.getDate()<10?'0':'') + date.getDate();
        let d2 = date.getMonth()+1;
        d2 = (d2<10?'0':'') + d2;
        let d3 = date.getFullYear();
        let d4 = (date.getHours()<10?'0':'') + date.getHours();
        let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
        return `${d1}/${d2}/${d3} ${d4}:${d5}`;
    }

    const days = [];
    for (let i = 1; i <= 730; i++) {
        days.push(<Option key={i}>{i}</Option>);
    }

    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    return (
        <React.Fragment>
            <Modal width={500} className="modal-mini bg_chester" title="Добавление обращения" mask={false} visible={props.state.modal === 'potservice_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление обращения</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {'profile' in props.state.pclientinfo ? (
                                <Form name="form" form={form11} onFinish={serviceadd} className="form" layout="vertical">
                                    {
                                        form11.setFieldsValue({ 
                                            pcliid:props.state.pclientinfo.profile.id
                                        })
                                    }
                                    <Form.Item hidden  className="max400" name="pcliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item  className="max400" name="service" label="Обращение" >
                                        <TextArea showCount maxLength={2000} rows={4} />
                                    </Form.Item>
                                    <Form.Item  className="max400" name="date" label="Дата обращения" >
                                        <DatePicker
                                            format="DD.MM.YYYY"
                                            className="datepickerfull"
                                        />
                                    </Form.Item>
                                    <Form.Item  className="max400" name="stock" label="Предложим">
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            <Option key='0'>Ничего</Option>
                                            { props.state.stocks.map( (item, index) => 
                                                <Option disabled={Number(item.block)} key={index} value={item.id}>{`${item.name} ${item.text.length > 0 ? `-${item.text}` : ``}`}</Option>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues[`stock`] !== currentValues[`stock`]}
                                    >
                                        {({ getFieldValue }) =>
                                            (Number(getFieldValue(`stock`)) > 0 ) ? (
                                                <Form.Item className="max400" name="days" label="Предложить в указанную дату (если нужно)" >
                                                    <DatePicker
                                                        format="DD.MM.YYYY"
                                                        disabledDate={disabledDate} 
                                                        className="datepickerfull"
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal width={500} className="modal-mini bg_chester" title="Изменение обращения" mask={false} visible={props.state.modal === 'potservice_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Изменение обращения</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {('id' in props.state.potentialinfo && 'profile' in props.state.pclientinfo) ? (
                                <Form name="form" form={form12} onFinish={serviceupd} className="form" layout="vertical">
                                    {
                                        form12.setFieldsValue({
                                            pcliid:props.state.pclientinfo.profile.id,
                                            potserid:props.state.potentialinfo.id,
                                            service:props.state.potentialinfo.service,
                                            stock:props.state.potentialinfo.stock
                                        })
                                    }
                                    {
                                        Number(props.state.potentialinfo.date) > 0 &&
                                        form12.setFieldsValue({ 
                                            date:moment(fulltime(props.state.potentialinfo.date), "DD.MM.YYYY")
                                        })
                                    }
                                    {
                                        Number(props.state.potentialinfo.stock) > 0 &&
                                        form12.setFieldsValue({ 
                                            days:moment(fulltime(Number(props.state.potentialinfo.date)+(86400*Number(props.state.potentialinfo.days))), "DD.MM.YYYY")
                                        })
                                    }
                                    <Form.Item hidden className="max400" name="pcliid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item hidden className="max400" name="potserid" label="ID" rules={[{ required: true, message: 'Пожалуйста, укажите ID!' }]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item className="max400" name="service" label="Обращение" >
                                        <TextArea showCount maxLength={2000} rows={4} />
                                    </Form.Item>
                                    <Form.Item className="max400" name="date" label="Дата обращения" >
                                        <DatePicker
                                            format="DD.MM.YYYY"
                                            className="datepickerfull"
                                        />
                                    </Form.Item>
                                    <Form.Item className="max400" name="stock" label="Предложим">
                                        <Select showSearch filterOption={(input, option) =>option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            <Option key='0'>Ничего</Option>
                                            { props.state.stocks.map( (item, index) => 
                                                <Option disabled={Number(item.block)} key={index} value={item.id}>{`${item.name} ${item.text.length > 0 ? `-${item.text}` : ``}`}</Option>
                                            ) }
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) => prevValues[`stock`] !== currentValues[`stock`]}
                                    >
                                        {({ getFieldValue }) =>
                                            (Number(getFieldValue(`stock`)) > 0 ) ? (
                                                <Form.Item className="max400" name="days" label="Предложить в указанную дату (если нужно)" >
                                                    <DatePicker
                                                        format="DD.MM.YYYY"
                                                        disabledDate={disabledDate} 
                                                        className="datepickerfull"
                                                    />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>
                                    <div className="fcenter">
                                        <Form.Item>
                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini" width="100%" zIndex="900" key="m1" title="Обращения потенциальных клиентов" mask={false} visible={props.state.panel.indexOf( 'potential_view' ) != -1 } onOk={() => props.panel('potential_view')} onCancel={() => props.panel('potential_view')} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-logo"><img alt="" src={Client_View} /></div>
                    <div className="modal-mini-header-title title-big">Обращения потенциальных клиентов</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <React.Fragment>
                            {Object.keys(props.state.pclientinfo).length > 0 ? (
                                <div className="tworow">
                                    {'profile' in props.state.pclientinfo ?
                                        <div>
                                            <Divider key="d1" >Контактные данные</Divider>
                                            <Form {...layout} style={{padding:24}} name="form" form={form1} onFinish={potupd} className="form" >
                                                { form1.setFieldsValue({ 
                                                    pcliid:props.state.pclientinfo.profile.id,
                                                    lastname:props.state.pclientinfo.profile.lastname,
                                                    firstname:props.state.pclientinfo.profile.firstname,
                                                    patronymic:props.state.pclientinfo.profile.patronymic,
                                                    phone:props.state.pclientinfo.profile.phone.slice(1),
                                                    email:props.state.pclientinfo.profile.email,
                                                    sex:props.state.pclientinfo.profile.sex,
                                                    address:props.state.pclientinfo.profile.address
                                                }) }
                                                {
                                                    Number(props.state.pclientinfo.profile.birthday) > 0 &&
                                                    form1.setFieldsValue({ 
                                                        birthday:moment(fulltime(props.state.pclientinfo.profile.birthday), "DD.MM.YYYY")
                                                    })
                                                }
                                                {
                                                    Number(props.state.pclientinfo.profile.client_true) > 0 ? (
                                                        <Alert style={{marginBottom:20}} message="Есть в базе действующих клиентов" type="success" />
                                                    ) :
                                                    (
                                                        <Alert style={{marginBottom:20}} message="Отсутствует в базе действующих клиентов" type="error" />
                                                    )
                                                }
                                                <Form.Item hidden name={`pcliid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="lastname" label="Фамилия" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="patronymic" label="Отчество" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="sex" label="Пол" >
                                                    <Select>
                                                        <Option value="0">Неизвестно</Option>
                                                        <Option value="1">Мужской</Option>
                                                        <Option value="2">Женский</Option>
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name="birthday" label="Дата рождения" >
                                                    <DatePicker format="DD.MM.YYYY" className="datepickerfull" />
                                                </Form.Item>
                                                <Form.Item name="phone" label="Номер" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                                    <MaskedInput
                                                        prefix="+7"
                                                        mask="111-111-11-11"
                                                        placeholder="999-999-99-99"
                                                        size="11"
                                                        formatCharacters={{
                                                        'W': {
                                                            validate(char) { return /\w/.test(char ) },
                                                            transform(char) { return char.toUpperCase() }
                                                        }
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="email" label="E-mail" >
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name="address" label="Адрес" >
                                                    <Input />
                                                </Form.Item>
                                                <div className="fcenter">
                                                    <Space wrap>
                                                        <Form.Item>
                                                            <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                                        </Form.Item>
                                                        {Number(props.state.pclientinfo.profile.block) === 0 ?
                                                            <Button className="des-button" type="dashed" danger shape="round" onClick={() => props.api({ 'method':'potential_block', 'pcliid':props.state.pclientinfo.profile.id },false)} icon={<StopOutlined />}>Заблокировать</Button>
                                                        :
                                                            <Button className="des-button des-green" type="dashed" shape="round" onClick={() => props.api({ 'method':'potential_block', 'pcliid':props.state.pclientinfo.profile.id },false)} icon={<CheckOutlined />}>Разблокировать</Button>
                                                        }
                                                    </Space>
                                                </div>
                                            </Form>
                                        </div>
                                    :
                                        <Empty description="Информация о анкете недоступна" />
                                    }
                                    {'services' in props.state.pclientinfo ?
                                        <div>
                                            <Divider key="d1" >Список обращений</Divider>
                                            { props.state.pclientinfo.services.length > 0 ?
                                                <List
                                                    locale={{ emptyText: 
                                                        <Empty description='Список обращений пуст' />
                                                    }}
                                                    className="listscroll"
                                                    style={{maxHeight:500}}
                                                    itemLayout="horizontal"
                                                    dataSource={props.state.pclientinfo.services}
                                                    renderItem={(item,index) => (
                                                        <List.Item actions={[
                                                            <Tooltip title={`Редактировать`}>
                                                                <Link key="a2" onClick={() => servicepushupd(item.id)} ><EditOutlined style={{color: '#949494'}} /></Link>
                                                            </Tooltip>,
                                                            <Tooltip title={`Удалить`}>
                                                                <Popconfirm placement="topRight" onConfirm={() => props.api({ 'method':'potential_service_del', 'potserid':`${item.id}`, 'pcliid':props.state.pclientinfo.profile.id },true)} 
                                                                    title={
                                                                        <Space direction="vertical">
                                                                            <Text>Вы действительно хотите удалить данное обращение?</Text>
                                                                        </Space>
                                                                    } 
                                                                okText="Да" cancelText="Нет">
                                                                    <Link key="a2" ><DeleteOutlined style={{color: '#949494'}} /></Link>
                                                                </Popconfirm>
                                                            </Tooltip>
                                                        ]}>
                                                            <List.Item.Meta
                                                                description={
                                                                    <Space wrap direction="vertical">
                                                                        <div>
                                                                            <Text strong>Обращение: </Text>{item.pc_text}
                                                                        </div>
                                                                        <div>
                                                                            <Text strong>Предложение: </Text>{item.stock_name} {item.stock_text.length > 0 ? `-${item.stock_text}` : ``}
                                                                        </div>
                                                                        <div>
                                                                            <Text strong>Дата обращения: </Text>{props.lasttime(item.date,'Неизвестно когда','full')}
                                                                        </div>
                                                                        <div>
                                                                            <Text strong>Дата отправки: </Text>{Number(item.stock) > 0 ? props.lasttime(Number(item.date)+(86400*Number(item.pc_days)),'Неизвестно когда','mini') : 'Не напоминать'}
                                                                        </div>
                                                                    </Space>
                                                                }
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            :
                                                <div className="fcenter"><Empty description="Список пуст" /></div>
                                            }
                                            <div className="fcenter" style={{marginTop:20}}>
                                                <Button className="des-button" type="dashed" type="dashed" shape="round" onClick={() => servicepushadd()}>Добавить</Button>
                                            </div>
                                        </div>
                                    :
                                        <Empty description="Информация о обращениях недоступна" />
                                    }
                                </div>
                            ) : (
                                <Empty description="Информация о клиенте недоступна" />
                            )}
                        </React.Fragment>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Добавление клиента" mask={false} visible={props.state.modal === 'potential_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление клиента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form name="form" form={form3} onFinish={potadd} className="form" layout="vertical">
                            <Form.Item name="lastname" label="Фамилия" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="firstname" label="Имя" rules={[{ required: true, message: 'Пожалуйста, укажите имя клиента!' }]} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="patronymic" label="Отчество" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="sex" label="Пол">
                                <Select>
                                    <Option value="0">Неизвестно</Option>
                                    <Option value="1">Мужской</Option>
                                    <Option value="2">Женский</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="birthday" label="Дата рождения" >
                                <DatePicker format="DD.MM.YYYY" className="datepickerfull" />
                            </Form.Item>
                            <Form.Item name="phone" label="Номер телефона" rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона клиента!' }]}>
                                <MaskedInput
                                    prefix="+7"
                                    mask="111-111-11-11"
                                    placeholder="999-999-99-99"
                                    size="11"
                                    formatCharacters={{
                                    'W': {
                                        validate(char) { return /\w/.test(char ) },
                                        transform(char) { return char.toUpperCase() }
                                    }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="email" label="E-mail" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="address" label="Адрес" >
                                <Input />
                            </Form.Item>
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <Col key="k2_clients" span={24} style={{marginBottom:10}} >
                    <div className="floattask"><span onClick={() => potpushadd()}></span></div>
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col key="k2_clients" span={24} className="shadowscreen">
                    <Table 
                        className="table-padding"
                        onChange={props.htc} 
                        locale={{ emptyText: <Empty description="Список пуст" />}} 
                        dataSource={obj.potentials} 
                        scroll={{ x: 400 }} 
                        pagination={{ position: ['none', 'bottomLeft'] }}
                        rowKey="table1" 
                        columns={columns1}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {potpush(record.id)}
                            };
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;