import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Form, Input, Select, Modal, Card, Divider, Button, Empty, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;
const { Link, Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    async function clipush(id) {
        await props.setState( { clientinfo:[],clientstats:[] } );
        await props.api({
            'method':'fb_get',
            'cliid':id
        },false);
        props.modal('fb_view');
    }

    //* Отправка формы
    async function patientst_add(v) {
        v['method'] = 'patientst_add';
        await props.api(v,true);
    }
    
     //* Отправка формы
    async function patientst_upd(v) {
        v['method'] = 'patientst_upd';
        await props.api(v,true);
    }

    //* Выбор элемента для обновления
    async function patupd(v) {
        form2.setFieldsValue({ ptid:v.id, name:v.name, descr:v.descr }); 
    }

    return (
        <React.Fragment>
            <Modal className="modal-mini bg_chester" width={500} title="Добавление типа пациента" mask={false} visible={props.state.modal === 'patientst_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление типа пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form onFinish={patientst_add} form={form1} form1name="form1" layout="vertical">
                            <Form.Item name="name" rules={[{ required: true, message: 'Пожалуйста, укажите название' }]} label="Название" >
                                <Input bordered={false} placeholder="Название" />
                            </Form.Item>
                            <Form.Item name="descr" label="Описание">
                                <TextArea bordered={false} placeholder="Описание" autoSize />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={500} title="Обновление типа пациента" mask={false} visible={props.state.modal === 'patientst_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Обновление типа пациента</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form onFinish={patientst_upd} form={form2} form1name="form1" layout="vertical">
                            <Form.Item name="name" rules={[{ required: true, message: 'Пожалуйста, укажите название' }]} label="Название" >
                                <Input bordered={false} placeholder="Название" />
                            </Form.Item>
                            <Form.Item name="descr" label="Описание">
                                <TextArea bordered={false} placeholder="Описание" autoSize />
                            </Form.Item>
                            <Form.Item hidden name={`ptid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input />
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <div className="floattask"><span onClick={() => {props.modal('patientst_add');form1.resetFields();}}></span></div>
                <Col key="k2_clients" span={24} style={{padding:20}} className="" >
                    { props.state.patientst.length > 0 ?
                        <Space wrap className="cardlist">
                            {props.state.patientst.map( list => 
                                <Card 
                                    className="card_no_padding"
                                    bordered={false} 
                                    style={{width:'100%'}}
                                    key={list.id}
                                >
                                    <div className="header_title header_act">
                                        <div>
                                            {list.name}
                                        </div>
                                        <div>
                                            <Tooltip title={`Редактировать`}>
                                                <EditOutlined style={{color:'#ffffff'}} onClick={() => {patupd(list); props.modal('patientst_upd');}} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="body_card bg_dog">
                                        <pre className="body_card_text">{list.descr}</pre>
                                        <div className="body_card_bottom">
                                            <Tooltip title={Number(list.block) === 0 ? 'Заблокировать' : 'Разблокировать'}>
                                                {Number(list.block) === 0 ?
                                                    <EyeInvisibleOutlined className="vis_but" onClick={() => props.api({ 'method':'patientst_block', 'ptid':`${list.id}` },false)} />
                                                :
                                                    <EyeOutlined className="vis_but" onClick={() => props.api({ 'method':'patientst_block', 'ptid':`${list.id}` },false)} />
                                                }
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Card>
                            )
                            }
                        </Space>
                    :
                        <div className="fcenter"><Empty /></div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;