import { hot } from 'react-hot-loader/root';

import React from 'react';

import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
import 'antd/dist/antd.css';
import './App.css';

import { Layout, ConfigProvider, Tooltip, notification, Modal, List } from 'antd';
import { SettingOutlined, AppstoreOutlined, BarsOutlined, ImportOutlined, InstagramOutlined, LineChartOutlined, TeamOutlined, PhoneOutlined, PercentageOutlined, UserAddOutlined, FormOutlined, BarChartOutlined, SendOutlined, FireOutlined, HeartOutlined, LikeOutlined } from '@ant-design/icons';

//@ Служебные
import Auth 		                        from './components/Auth/Auth';
import GlobLoadpage 		      from './components/Global/Loadpage';
import GlobHeader 		           from './components/Global/Header';
import GlobMenu 		           from './components/Global/Leftmenu';
import GlobMenuF 		           from './components/Global/Leftmenufloat';
import Dev 		                           from './components/Panels/Dev';

//@ Панели
import Sms 		                          from './components/Panels/Sms';
import Data 		                        from './components/Panels/Data';
import Settings 		                  from './components/Panels/Settings';
import Stats 		                  from './components/Panels/Stats';
import Patientst 		                  from './components/Panels/Patientst';
import Services 		                  from './components/Panels/Services';
import Rec 		                  from './components/Panels/Rec';
import Clients 		                  from './components/Panels/Clients';
import Tasks 		                  from './components/Panels/Tasks';
import Script 		                  from './components/Panels/Script';
import Potential 		                  from './components/Panels/Potential';
import Stocks 		                  from './components/Panels/Stocks';
import Consults 		                  from './components/Panels/Consults';
import Instagram 		                  from './components/Panels/Instagram';
import Sites 		                  from './components/Panels/Sites';

const { Content } = Layout;

const notif = (type,title, text) => {
  notification[type]({
    message: title,
    description:text,
    placement:'bottomRight',
  });
};

class App extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      auth: 0,
      error: ['',''],
      da: [],
      clients: [],
      proj3: ['1',[],[],[],'[]',[]],                                // 0-Tab,1-список задач,2-список юзеров,3-форма для задачи,4-список задачи
      loading: true,
      moreload: true,
      modal: '',
      panel: [],
      active: 0,
      menu: [],
      api: 'https://clinic.rus2.ru/api/6/',
      balance: 0,
      success: false,
      potentials: [],
      pclientinfo: [],
      potentialinfo: [],
      stocks: [],
      settings: [],
      contacts: [],
      revitem: [],
      reviews: [],
      client: [],
      emp: [],
      emp2: [],
      times: [],
      sms: [],
      pers: [],
      persitem: [],
      usersitem: [],
      clientinfo: [],
      clientstats: [],
      search: '',
      stats: [],
      width: 0,
      users: [],
      groups: [],
      phone: '',
      leftmenu: 0,
      patientst: [],
      patientinfo: [],
      services: [],
      rec: [],
      clis: [],
      pattitem: [],
      scriptitem: [],
      scripts: [],
      recordinfo: [],
      returnid: 0,
      consults: [],
      consultinfo: [],
      filteredInfo: null,
      sortedInfo: null,
      docs: [],
      cards: [],
      cardsitem: [],
      cardsdoctors: [],
      sites: [],
      site_get: [],
    };
    this.onChange 	    = this.onChange.bind(this);
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    if (this.getCookie('token') !== undefined) {
      await this.login(1,1,this.getCookie('token'));
    } else {
      await this.setState( { auth:1 } );
    }
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    //this.setState({ leftmenu:0 })
  };

  //* Текущее время 
  totime() {
    let date = new Date();
    let d1 = (date.getDate()<10?'0':'') + date.getDate();
    let d2 = date.getMonth()+1;
    d2 = (d2<10?'0':'') + d2;
    let d3 = date.getFullYear();
    let d4 = (date.getHours()<10?'0':'') + date.getHours();
    let d5 = (date.getMinutes()<10?'0':'') + date.getMinutes();
    return `${d1}.${d2}.${d3} - ${d4}-${d5}`;
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Вход
  async login (l,p,t) {
    await this.setState( { auth: 0 } );
    let user = { login: l, pass: p, method: 'auth' };
    if (t) {
      user = { token: t, method: 'auth' };
    }
    
    await this.postRequest(`${this.state.api}`, user)
    .then(async json => {
      var data = json;
      if(data.code) {
        let da          = ( data.da ) ? data.da : {};
        let menu          = ( data.menu ) ? data.menu : [];
        let balance = ( data.balance ) ? data.balance : 0; 
        let emp = ( data.emp ) ? data.emp : [];
        let emp2 = ( data.emp2 ) ? data.emp2 : [];
        
        await this.setState( {
          da: da,
          menu: menu,
          balance: balance,
          emp:emp,
          emp2:emp2
        } );
  
        if (!t) {
          this.setCookie('token', data.da.token);
        }

        if (menu.length > 0) {
          await this.menugo(menu[0].id);
        }

        await this.setState( {
          auth:2,
          loading: false
        } );
      }
      if(data.error) {
        await this.setState( { auth:1, error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
      }
    })
    .catch( async err => {
      await this.setState( { auth:1, error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
  }

  //* Текст поиска
  onChange (e) { 
    this.setState({ search:e.target.value.replace(/\s+/g,' ') }); 
  }

  async anchor(e) {
    window.location.href = '#'+e;
  }

  //* Получение информации
  async api(v,s) {
    var out = [];
    if (s) {
      await this.setState( { loading: true, moreload:true, modal:'' } );
    } else {
      await this.setState({ moreload:true });
    }
    
    v['token'] = this.getCookie('token');
    await this.postRequest(`${this.state.api}`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        let da          = ( data.da ) ? data.da : {};
        let menu          = ( data.menu ) ? data.menu : []; 
        let balance = ( data.balance ) ? data.balance : 0; 
        let emp = ( data.emp ) ? data.emp : [];
        let emp2 = ( data.emp2 ) ? data.emp2 : [];

        await this.setState( {
          da: da,
          menu: menu,
          balance: balance,
          emp:emp,
          emp2:emp2
        } );

        //* Методы
        switch(v['method']) {
          //@ SMS
          case 'cli_search':
            var client 		= ( data.client ) ? data.client : [];
            
            await this.setState( { client:client } );
            break;
          case 'cli_add':
            client 		= ( data.client ) ? data.client : [];
            await this.setState( { client:client } );
            notif('success','Клиент добавлен!','');
            break;
          case 'sms_send':
            notif('success','SMS добавлено в очередь отправки!','');
            this.timesuccess();
            break;
            
          //@ Клиенты
          case 'patient_get':
            var patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { patientinfo:patientinfo } );
            break;
          case 'patient_add':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Пациент добавлен!','');
            break;
          case 'patient_upd':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo } );
            notif('success','Пациент обновлён!','');
            break;
          case 'patient_prop':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo } );
            notif('success','Параметры пациента обновлены!','');
            break;
          case 'patient_card':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo } );
            notif('success','Карточка пациента обновлена!','');
            break;
          case 'patient_block':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
            await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo } );
            notif('success','Статус пациента обновлён!','');
            break;
          case 'patient_docs':
            var docs 		= ( data.docs ) ? data.docs : [];
            await this.setState( { docs:docs } );
            break;
          case 'patient_cards':
            var cards 		= ( data.cards ) ? data.cards : [];
            var pers = ( data.pers ) ? data.pers : [];
            await this.setState( { cards:cards, pers:pers } );
            break;
          case 'patient_cards_add':
            cards 		= ( data.cards ) ? data.cards : [];
            pers = ( data.pers ) ? data.pers : [];
            await this.setState( { cards:cards, pers:pers } );
            notif('success','Новая карта создана!','');
            break;
          case 'patient_cards_get':
            var cardsitem 		= ( data.info ) ? data.info : [];
            await this.setState( { cardsitem:cardsitem } );
            break;
          case 'patient_cards_upd':
            notif('success','Карта обновлена!','');
            break;

          //@ Клиенты
          case 'import_cli_pot':
            var pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Данные импортированы!','');
            break;

          case 'potentials':
            var potentials 		= ( data.potentials ) ? data.potentials : [];
            await this.setState( { potentials:potentials } );
            break;
          case 'potential_get':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            var get    = ( data.patientst ) ? data.patientst : [];
            var stocks    = ( data.stocks ) ? data.stocks : [];
            var services  = ( data.services ) ? data.services : [];
            await this.setState( { pclientinfo:pclientinfo, stocks:stocks, services:services } );
            break;
          case 'potential_add':
            potentials 		= ( data.potentials ) ? data.potentials : [];
            var returnid = ( data.returnid ) ? data.returnid : 0;
            await this.setState( { potentials:potentials } );
            notif('success','Потенциальный клиент добавлен!','');
          await this.api({
              'method':'potential_get',
              'pcliid':returnid
            },false);
            await this.panel('potential_view');
            break;
          case 'potential_upd':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Потенциальный клиент обновлён!','');
            break;
          case 'potential_block':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Статус потенциального клиента обновлён!','');
            break;
          case 'potential_service_add':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Данные добавлены!','');
            break;
          case 'potential_service_upd':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Данные обновлены!','');
            break;
          case 'potential_service_get':
            var potentialinfo 		= ( data.get ) ? data.get : [];
            await this.setState( { potentialinfo:potentialinfo } );
            await this.modal('potservice_upd');
            break;
          case 'potential_service_del':
            pclientinfo 		= ( data.pclientinfo ) ? data.pclientinfo : [];
            await this.setState( { pclientinfo:pclientinfo } );
            notif('success','Данные удалены!','');
            break;

          case 'import_pot_cli':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Данные импортированы!','');
            break;

          case 'cashback_upd':
            var clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Бонусы обновлены!','');
            break;

          case 'clients':
            var clients 		= ( data.clients ) ? data.clients : [];
            await this.setState( { clients:clients } );
            break;
          case 'client_get':
            var clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            var get    = ( data.patientst ) ? data.patientst : [];
            await this.setState( { clientinfo:clientinfo, patientst: get } );
            break;
          case 'client_add':
            clients 		= ( data.clients ) ? data.clients : [];
            var returnid = ( data.returnid ) ? data.returnid : 0;
            await this.setState( { clients:clients } );
            notif('success','Клиент добавлен!','');
            await this.api({
              'method':'client_get',
              'cliid':returnid
            },false);
            await this.panel('client_view');
            break;
          case 'client_upd':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Клиент обновлён!','');
            break;
          case 'client_block':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Статус клиента обновлён!','');
            break;
          case 'client_pas':
            notif('success','Пароль отправлен!','');
            break;
          case 'client_notif_add':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Напоминание добавлено!','');
            break;
          case 'client_notif_del':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','Напоминание удалено!','');
            break;
          case 'prefb':
            let times = ( data.times ) ? data.times : [];
            await this.setState( { times:times } );
            break;
          case 'clis':
            var clis 		= ( data.clients ) ? data.clients : [];
            await this.setState( { clis:clis } );
            break;
          case 'cli_get':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            break;
          case 'cli_del':
            var clis 		= ( data.clis ) ? data.clis : [];
            await this.setState( { clis:clis } );
            notif('success','Клиент удалён!','');
            this.timesuccess();
            break;
          case 'cli_stats':
            var clientstats 		= ( data.clientstats ) ? data.clientstats : [];
            await this.setState( { clientstats:clientstats } );
            break;
          case 'sms_del':
            clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
            await this.setState( { clientinfo:clientinfo } );
            notif('success','SMS удалено!','');
            break;

          //@ Настройки
          case 'settings':
            var settings = ( data.settings ) ? data.settings : [];
            var contacts = ( data.contacts ) ? data.contacts : [];
            var reviews = ( data.reviews ) ? data.reviews : [];
            var sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            var users = ( data.users ) ? data.users : [];
            var groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            break;
          case 'settings_save':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Настройки сохранены!','');
            this.timesuccess();
            break;
          case 'plat_add':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Платформа добавлена!','');
            this.timesuccess();
            break;
          case 'plat_del':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Платформа удалена!','');
            this.timesuccess();
            break;

          //@ Контакты
          case 'cont_save':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Контакты обновлены!','');
            this.timesuccess();
            break;

          //@ Вопросы
          case 'rev_add':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Вопрос добавлен!','');
            this.timesuccess();
            break;
          case 'rev_upd':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Вопрос обновлён!','');
            this.timesuccess();
            break;
          case 'rev_del':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Вопрос удалён!','');
            this.timesuccess();
            break;
          case 'rev_mv':
            reviews = ( data.reviews ) ? data.reviews : [];
            await this.setState( { reviews:reviews } );
            notif('success','Порядок изменён!','');
            break;

          //@ SMS
          case 'sms_upd':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Настройки SMS сохранены!','');
            this.timesuccess();
            break;

          //@ Персонал
          case 'pers_add':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник добавлен!','');
            this.timesuccess();
            break;
          case 'pers_upd':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник обновлён!','');
            this.timesuccess();
            break;
          case 'pers_del':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник удалён!','');
            this.timesuccess();
            break;
          case 'pers_mv':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Порядок изменён!','');
            this.timesuccess();
            break;

          //@ Учетные записи персонала
          case 'users_add':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            var modal 		= ( data.modal ) ? data.modal : [];
            groups = ( data.groups ) ? data.groups : [];
            this.modalresp(modal)
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник добавлен! Пароль отправлен на почту администратора.','');
            this.timesuccess();
            break;
          case 'users_upd':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник обновлён!','');
            this.timesuccess();
            break;
          case 'users_del':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Сотрудник удалён!','');
            this.timesuccess();
            break;
          case 'users_pas':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            var modal 		= ( data.modal ) ? data.modal : [];
            groups = ( data.groups ) ? data.groups : [];
            this.modalresp(modal)
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Пароль сотрудника обновлён и был отправлен на почту администратора.','');
            this.timesuccess();
            break;
          case 'users_tg':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Ключ обновлен!','');
            this.timesuccess();
            break;
          case 'users_tgmy':
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Ключ обновлен!','');
            this.timesuccess();
            break;
          case 'group_add':
            //this.anchor('');
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Группа добавлена!','');
            this.timesuccess();
            //await setTimeout(this.anchor('groups'), 1000);
            break;
          case 'group_upd':
            //this.anchor('');
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Группа обновлена!','');
            this.timesuccess();
            //await setTimeout(this.anchor('groups'), 1000);
            break;
          case 'group_del':
            //this.anchor('');
            settings = ( data.settings ) ? data.settings : [];
            contacts = ( data.contacts ) ? data.contacts : [];
            reviews = ( data.reviews ) ? data.reviews : [];
            sms = ( data.sms ) ? data.sms : [];
            pers = ( data.pers ) ? data.pers : [];
            users = ( data.users ) ? data.users : [];
            groups = ( data.groups ) ? data.groups : [];
            await this.setState( { settings:settings,contacts:contacts,reviews:reviews,sms:sms,pers:pers,users:users,groups:groups } );
            notif('success','Группа удалена!','');
            this.timesuccess();
            //await setTimeout(this.anchor('groups'), 1000);
            break;

          case 'users_pasu':
            modal 		= ( data.modal ) ? data.modal : [];
            await this.delCookie('token');
            await this.setCookie('token', data.da.token);
            this.modalresp(modal)
            notif('success','Ваш пароль обновлён','');
            this.timesuccess();
            break;

          //@ Stats
          case 'stats':
              var stats = ( data.stats ) ? data.stats : [];
              await this.setState( { stats:stats } );
              break;
          //@ Задачи
          case 'tasks_list':
              var proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              proj3[3] = [];
              await this.setState( {
                proj3: proj3, groups: groups
              } );
             break;
          case 'tasks_add':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Задача добавлена!','');
              break;
          case 'tasks_del':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Задача удалена!','');
              break;
          case 'tasks_col':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              break;
          case 'tasks_push':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              break;
          case 'tasks_upd':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Задача обновлена!','');
              break;
          case 'tasks_comp':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Статус задачи обновлен!','');
              break;
            case 'tasks_item':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Статус пункта обновлен!','');
              break;
            case 'tasks_work':
              proj3 = this.state.proj3;
              get    = ( data.list ) ? data.list : [];
              users    = ( data.users ) ? data.users : [];
              groups = ( data.groups ) ? data.groups : [];
              proj3[1] = get;
              proj3[2] = users;
              await this.setState( {
                proj3: proj3, groups: groups
              } );
              notif('success','Статус задачи обновлен!','');
              break;
            case 'newpass':
              notif('success','Запрос на смену пароля отправлен! Ожидайте SMS.','');
              break;

            //@ Рекомендации
            case 'rec':
              get    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                rec: get
              } );
              break;
            case 'rec_add':
              get    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                rec: get
              } );
              notif('success','Рекомендация добавлена!','');
              break;
            case 'rec_add_inc':
              var rec    = ( data.rec ) ? data.rec : [];
              var recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { rec:rec, clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              notif('success','Рекомендация добавлена!','');
              break;
            case 'rec_upd':
              get    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                rec: get
              } );
              notif('success','Рекомендация обновлена!','');
              break;
            case 'rec_block':
              get    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                rec: get
              } );
              notif('success','Статус рекомендации обновлён!','');
              break;

            //@ Типы пациентов
            case 'patientst':
              get    = ( data.patientst ) ? data.patientst : [];
              await this.setState( {
                patientst: get
              } );
              break;
            case 'patientst_add':
              get    = ( data.patientst ) ? data.patientst : [];
              await this.setState( {
                patientst: get
              } );
              notif('success','Тип пациента добавлен!','');
              break;
            case 'patientst_upd':
              get    = ( data.patientst ) ? data.patientst : [];
              await this.setState( {
                patientst: get
              } );
              notif('success','Тип пациента обновлён!','');
              break;
            case 'patientst_block':
              get    = ( data.patientst ) ? data.patientst : [];
              await this.setState( {
                patientst: get
              } );
              notif('success','Тип пациента обновлён!','');
              break;

            //@ Приёмы
            case 'record_get':
              recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              break;
            case 'record_add':
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo } );
              notif('success','Новый приём создан!','');
              break;
            case 'record_cancel':
              recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              notif('success','Приём отменён!','');
              break;
            case 'record_done':
              recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              notif('success','Приём завершён!','');
              break;
            case 'record_date':
              recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              notif('success','Приём перенесён!','');
              break;

            case 'record_edit':
              recordinfo 		= ( data.recordinfo ) ? data.recordinfo : [];
              clientinfo 		= ( data.clientinfo ) ? data.clientinfo : [];
              patientinfo 		= ( data.patientinfo ) ? data.patientinfo : [];
              await this.setState( { clientinfo:clientinfo, patientinfo:patientinfo, recordinfo:recordinfo } );
              notif('success','Приём обновлён!','');
              break;

            case 'record_docs':
              var docs 		= ( data.docs ) ? data.docs : [];
              await this.setState( { docs:docs } );
              break;

            //@ Услуги
            case 'services':
              var services    = ( data.services ) ? data.services : [];
              get    = ( data.patientst ) ? data.patientst : [];
              await this.setState( {
                services: services, patientst: get
              } );
              break;
            case 'services_add':
              get    = ( data.services ) ? data.services : [];
              await this.setState( {
                services: get
              } );
              notif('success','Услуга добавлена!','');
              break;
            case 'services_upd':
              get    = ( data.services ) ? data.services : [];
              await this.setState( {
                services: get
              } );
              notif('success','Услуга обновлена!','');
              break;
            case 'services_block':
              get    = ( data.services ) ? data.services : [];
              await this.setState( {
                services: get
              } );
              notif('success','Статус услуги обновлён!','');
              break;

            //@ Акции
            case 'stocks':
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                stocks: stocks
              } );
              break;
            case 'stock_add':
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                stocks: stocks
              } );
              notif('success','Акция добавлена!','');
              break;
            case 'stock_upd':
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                stocks: stocks
              } );
              notif('success','Акция обновлена!','');
              break;
            case 'stock_block':
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                stocks: stocks
              } );
              notif('success','Статус акции обновлён!','');
              break;

            //@ Консультации
            case 'consults':
              var consults  = ( data.consults ) ? data.consults : [];
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                consults: consults
              } );
              break;
            case 'consult_get':
              var consultinfo  = ( data.consultinfo ) ? data.consultinfo : [];
              await this.setState( {
                consultinfo: consultinfo
              } );
              break;
            case 'consult_upd':
              var consultinfo  = ( data.consultinfo ) ? data.consultinfo : [];
              await this.setState( {
                consultinfo: consultinfo
              } );
              notif('success','Заявка обновлена!','');
              break;
            case 'consult_done':
              var consultinfo  = ( data.consultinfo ) ? data.consultinfo : [];
              await this.setState( {
                consultinfo: consultinfo
              } );
              notif('success','Заявка закрыта!','');
              break;

            //@ Сценарии
            case 'scripts':
              var scripts  = ( data.scripts ) ? data.scripts : [];
              var patientst    = ( data.patientst ) ? data.patientst : [];
              var services    = ( data.services ) ? data.services : [];
              var rec    = ( data.rec ) ? data.rec : [];
              stocks    = ( data.stocks ) ? data.stocks : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts, stocks:stocks
              } );
              break;
            case 'script_add':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий добавлен!','');
              break;
            case 'script_upd':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'script_del':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий удалён!','');
              break;
            case 'scriptf_add':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий добавлен!','');
              break;
            case 'scriptf_upd':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'scriptf_del':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий удалён!','');
              break;
            case 'scriptpot_add':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий добавлен!','');
              break;
            case 'scriptpot_upd':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'scriptpot_del':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий удалён!','');
              break;
            case 'scriptafter_add':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий добавлен!','');
              break;
            case 'scriptafter_upd':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'scriptafter_del':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий удалён!','');
              break;
            case 'scriptcard_add':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий добавлен!','');
              break;
            case 'scriptcard_upd':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'scriptcard_update':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий обновлён!','');
              break;
            case 'scriptcard_del':
              scripts  = ( data.scripts ) ? data.scripts : [];
              await this.setState( {
                scripts: scripts
              } );
              notif('success','Сценарий удалён!','');
              break;
            case 'conn_add_pt_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              var patientst    = ( data.patientst ) ? data.patientst : [];
              var services    = ( data.services ) ? data.services : [];
              var rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь добавлена!','');
              break;
            case 'conn_del_pt_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              patientst    = ( data.patientst ) ? data.patientst : [];
              services    = ( data.services ) ? data.services : [];
              rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь удалена!','');
              break;
            case 'conn_add_pt_ser':
              scripts  = ( data.scripts ) ? data.scripts : [];
              var patientst    = ( data.patientst ) ? data.patientst : [];
              var services    = ( data.services ) ? data.services : [];
              var rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь добавлена!','');
              break;
            case 'conn_del_pt_ser':
              scripts  = ( data.scripts ) ? data.scripts : [];
              var patientst    = ( data.patientst ) ? data.patientst : [];
              var services    = ( data.services ) ? data.services : [];
              var rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь удалена!','');
              break;
            case 'conn_add_ser_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              patientst    = ( data.patientst ) ? data.patientst : [];
              services    = ( data.services ) ? data.services : [];
              rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь добавлена!','');
              break;
            case 'conn_del_ser_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              patientst    = ( data.patientst ) ? data.patientst : [];
              services    = ( data.services ) ? data.services : [];
              rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь удалена!','');
              break;
            case 'conn_add_noser_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              patientst    = ( data.patientst ) ? data.patientst : [];
              services    = ( data.services ) ? data.services : [];
              rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь добавлена!','');
              break;
            case 'conn_del_noser_rec':
              scripts  = ( data.scripts ) ? data.scripts : [];
              patientst    = ( data.patientst ) ? data.patientst : [];
              services    = ( data.services ) ? data.services : [];
              rec    = ( data.rec ) ? data.rec : [];
              await this.setState( {
                patientst: patientst, rec: rec, services: services, scripts: scripts
              } );
              notif('success','Связь удалена!','');
              break;
            case 'sites':
              get  = ( data.list ) ? data.list : [];
              await this.setState( {
                sites: get
              } );
              break;
            case 'site_get':
              get  = ( data.get ) ? data.get : [];
              await this.setState( {
                site_get: get
              } );
              break;
          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        notif('error',`${data.error[0]}`,`${data.error[1].length > 0 ? `${data.error[1]}`:''}`);
        if (data.error[0] === 'Неверный логин или пароль') {
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
        }
      }
      out = data;
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: false } );
    } else {
      await this.setState({ moreload:false });
    }
    if (this.state.stats.length > 0) {
      setTimeout(() => {this.setState( {width:'100%'} )}, 1000)
    }
    return out;
  }

  async timesuccess () {
    await this.setState( { success:true } );
    await setTimeout(() => this.setState( { success:false } ), 2 * 1000);
  };

  //* Получение нужного куки
  getCookie(name) {
    let matches = document.cookie.match(new RegExp( "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)" ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  //* Линк на пункт меню
  menulink = (e) => { let id = e.currentTarget.dataset.id; this.menugo(id); }
  
  //* Обнуление Tab разделов
  async deltabs() {

  }

  //* Модальное окно после запроса
  async modalresp(resp) {
    if ('title' in resp && 'data' in resp) {
      if (resp.data instanceof Array) {
        await Modal.info({
          title: resp.title,
          content: (
            <List
                itemLayout="horizontal"
                dataSource={resp.data}
                renderItem={itemlist => (
                <List.Item>
                    <List.Item.Meta
                    title={itemlist['name']}
                    description={itemlist['text']}
                    />
                </List.Item>
                )}
            />
          ),
          okType: 'dashed',
          okText: 'Продолжить',
          onOk() {},
        });   
      }
    }
  }

  //* Переход на другой раздел
	async menugo(a) {
    await this.setState( { loading: true, leftmenu:0 } );
    await this.deltabs();
    if (a < 1) {
      await this.setState( { active:a } );
      switch(a) {
        case '0':
          await this.delCookie('token');
          await this.setState( {auth: 1, error: ['','']} );
          await notif('success','Вы успешно вышли!','Пожалуйста, подождите пару секунд');
          await window.location.reload();
          break;
        case '-1':
          await this.api({ 'method':'auth' },true);
          break;
        default:  break;
      }
      await this.setState( { loading: false } );
    } else {
      switch(a) {
         //* Данные
         case '1':
          await this.api({ 'method':'prefb' },true);
          break;
         //* Клиенты
        case '2':
          await this.api({ 'method':'clis' },true);
          break;
        //* Статистика
        case '3':
          await this.api({ 'method':'stats' },true);
          break;
        //* Настройка
        case '4':
          await this.api({ 'method':'settings' },true);
          break;
        //* Задачи
        case '5':
          await this.api({ 'method':'tasks_list' },true);
          break;
        //* Отзывы
        case '6':
          await this.api({ 'method':'clients' },true);
          break;
        //* Рекомендации
        case '7':
          await this.api({ 'method':'rec' },true);
          break;
        //* Услуги
        case '8':
          await this.api({ 'method':'services' },true);
          break;
        //* Типы пациентов
        case '9':
          await this.api({ 'method':'patientst' },true);
          break;
        //* Сценарии
        case '10':
          await this.api({ 'method':'scripts' },true);
          break;
        //* Потенциальные
        case '11':
          await this.api({ 'method':'potentials' },true);
          break;
        //* Акции
        case '12':
          await this.api({ 'method':'stocks' },true);
          break;
        //* Консультации
        case '13':
          await this.api({ 'method':'consults' },true);
          break;
        //* Instagram
        case '14':
          break;
        //* Сайты
        case '15':
          await this.api({ 'method':'sites' },true);
          break;
        default:  break;
      }
      await this.setState( { active:a } );
      await this.setState( { loading: false } );
    }
  }

  icons(n) {
    switch(n) {
      case 'tasks': return <BarsOutlined />;
      case 'clientadd': return <UserAddOutlined />;
      case 'sms': return <SendOutlined />;
      case 'clients': return <TeamOutlined />;
      case 'feedback': return <LikeOutlined />;
      case 'stats': return <BarChartOutlined />;
      case 'settings': return <SettingOutlined />;
      case 'services': return <FireOutlined />;
      case 'rec': return <LikeOutlined />;
      case 'patientst': return <HeartOutlined />;
      case 'consult': return <PhoneOutlined />;
      case 'script': return <FormOutlined />;
      case 'stock': return <PercentageOutlined />;
      case 'instagram': return <InstagramOutlined />;
      case 'sites': return <LineChartOutlined />;
      case '0': return <ImportOutlined />;
      default: return <AppstoreOutlined />;
    }
  }

  //* Добавление куки
  setCookie(name, value, options = {}) {
    options = { path: '/', ...options };
    if (options.expires instanceof Date) { options.expires = options.expires.toUTCString(); }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) { updatedCookie += "=" + optionValue; }
    }
    document.cookie = updatedCookie;
  }

  //* Сортировка
  htc = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  //* Удаление куки
  delCookie (name) { this.setCookie(name, "", { 'max-age': -1 }) };

  //* Случайный цвет
  randcolor() {
    var arr = ['#cc0029', '#E14015', '#f56a00', '#FA9500', '#ffbf00', '#9EB51E', '#3caa3c', '#1EA675', '#00a2ae', '#42aaff', '#2E82E8', '#1959d1', '#7265e6'];
    var rand = Math.floor(Math.random() * arr.length);
    return arr[rand];
  }

  //* Модальные окна
  async modal(t) {
    await this.setState( { modal:t } );
  }

  //* Модальные окна
  async panel(t) {
    var panels = this.state.panel;
    if (panels.indexOf( t ) != -1) {
      var index = panels.indexOf(t);
      if (index > -1) panels.splice(index, 1);
    } else {
      await panels.push(t);
    }
    await this.setState( { panel:panels } );
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Полная дата
  fulltime(date,time ) {
    if (time === undefined) {time = '00:00';}
    var ot = new Date(date);
    var nt = new Date('1970-01-01T' + time + ':00');
    ot.setHours(nt.getHours(),nt.getMinutes());
    return ot;
  }

  //* Дата
  lasttime(time,empty,type) {
    let newtime 	= new Date();
		let oldtime 	= new Date();
		let notiftime 	= new Date( time * 1000 );
		newtime.setMinutes( newtime.getMinutes() );
		oldtime.setMinutes( newtime.getMinutes() - 1440 );
		notiftime.setMinutes( notiftime.getMinutes());
		let newdate 	= Math.floor( newtime.getTime()/1000 );
		let notifdate 	= Math.floor( notiftime.getTime()/1000 );
		let diff 		= newdate-notifdate;
		let monthlist 	= { "00":"янв ", "01":"фев ", "02":"мар ", "03":"апр ", "04":"мая ", "05":"июн ", "06":"июл ", "07":"авг ", "08":"сен ", "09":"окт ", "10":"ноя ", "11":"дек " };
    let monthlistnum 	= { "00":"01", "01":"02", "02":"03", "03":"04", "04":"05", "05":"06", "06":"07", "07":"08", "08":"09", "09":"10", "10":"11", "11":"12" };
		let info 		= '';
		if ( diff < 60 ) 	{ info = Math.floor( diff ) + this.decnum( Math.floor( diff ),[ ' секунду ',' секунды ',' секунд ' ] ) + " назад "; }
		if ( diff > 60 ) 	{ info = Math.floor( diff/60 ) + this.decnum( Math.floor( diff/60 ),[ ' минуту ',' минуты ',' минут '] ) + " назад "; }
		if ( diff > 1800 ) 	{ info = "полчаса назад "; }
		if ( diff > 3600 ) 	{ info = "час назад "; }
		if ( diff > 7200 ) 	{ info = "2 часа назад "; }
		if ( diff > 10800 ) { info = "3 часа назад "; }
		if ( diff > 14400 ) {
			if ( ( '0' + notiftime.getDate() ).slice( -2 ) === ( '0' + newtime.getDate() ).slice( -2 ) ) {
				info = "сегодня в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " "; 
			} else {
				info = "вчера в " + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + " ";
			}
		}
		if ( diff > 24*3600 ) {
			if ( ('0' + notiftime.getDate()).slice(-2) === ('0' + oldtime.getDate()).slice(-2) ) {
				info = "вчера в " + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + " "; 
			} else {
				info = ('0' + notiftime.getDate()).slice(-2) + ' ' + monthlist[('0' + notiftime.getMonth()).slice(-2)] + ' в ' + ('0' + notiftime.getHours()).slice(-2) + ':' + ('0' + notiftime.getMinutes()).slice(-2) + ' ';
			}
		}
		if ( diff > 2*24*3600 ) { info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let diffrev = notifdate-newdate;
		if ( diff === 0 ) 	{ info = "только что "; }
		if (diffrev > 0) { info = info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' '; }
		let full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года в ' + ( '0' + notiftime.getHours() ).slice( -2 ) + ':' + ( '0' + notiftime.getMinutes() ).slice( -2 ) + ' ';
		switch (type) {
      case 'last':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'micro':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + '.' + monthlistnum[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + '.' + notiftime.getFullYear();
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'mini':
        info = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        full = ( '0' + notiftime.getDate() ).slice( -2 ) + ' ' + monthlist[ ( '0' + notiftime.getMonth() ).slice( -2 ) ] + notiftime.getFullYear() + ' года ';
        break;
      case 'full':
        info = full;
        break;
      default: break;
    }
    if (time === '0') {
      info = full = empty;
    }
    return( <Tooltip title={full}>{info}</Tooltip> );
  }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>;
    const f_Login = (v) => { this.login(v['username'],v['password']); };

    return (
      <ConfigProvider locale={locale}>
        <Layout style={{ minHeight: "100vh" }}>
          {this.state.auth === 0 && <GlobLoadpage />}
          {this.state.auth === 1 && <Auth f_Login={f_Login} state={this.state} setState={this.setState.bind(this)} modal={this.modal} api={this.api.bind(this)} />}
          {this.state.auth === 2 &&
            <Layout className="lglobal">
              <Content>
                <GlobHeader state={this.state} menugo={this.menugo.bind(this)} />
                <GlobMenuF state={this.state} setState={this.setState.bind(this)} menugo={this.menugo.bind(this)} icons={this.icons} />
                {!this.state.loading ?
                  <div className="site-layout-background" style={{ padding: 24 }}>
                    {this.state.active === "1" &&
                      <Sms        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "2" &&
                       <Data        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "3" &&
                      <Stats state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "4" &&
                      <Settings        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)} decnum={this.decnum} />
                    }
                    {this.state.active === "5" &&
                      <Tasks         state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} randcolor={this.randcolor} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} /> 
                    }
                    {this.state.active === "6" &&
                      <Clients        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "7" &&
                      <Rec        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "8" &&
                      <Services        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "9" &&
                      <Patientst        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "10" &&
                      <Script        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "11" &&
                      <Potential        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "12" &&
                      <Stocks        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "13" &&
                      <Consults        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "14" &&
                      <Instagram        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                    {this.state.active === "15" &&
                      <Sites        state={this.state} setState={this.setState.bind(this)} modal={this.modal} panel={this.panel} api={this.api.bind(this)} lasttime={this.lasttime.bind(this)} fulltime={this.fulltime.bind(this)} htc={this.htc} onChange={this.onChange} notif={notif.bind(this)}  decnum={this.decnum} />
                    }
                  </div>
                  :
                  spin
                }
              </Content>
            </Layout>
          }
        </Layout>
      </ConfigProvider>
    );
  }
}

export default hot(App);