import React, { useRef } from 'react';
import  ReactToPrint   from 'react-to-print';
import { Typography, Row, Col, Space, Form, Input, Select, Modal, Card, Divider, Button, Empty, Tooltip, Alert } from 'antd';
import { EyeInvisibleOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { TextArea } = Input;
const { Option } = Select;
const { Link, Text, Title } = Typography;

const Global = props => {

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    async function clipush(id) {
        await props.setState( { clientinfo:[],clientstats:[] } );
        await props.api({
            'method':'fb_get',
            'cliid':id
        },false);
        props.modal('fb_view');
    }

    //* Отправка формы
    async function services_add(v) {
        v['method'] = 'services_add';
        await props.api(v,true);
    }
    
     //* Отправка формы
    async function services_upd(v) {
        v['method'] = 'services_upd';
        await props.api(v,true);
    }

     //* Выбор элемента для обновления
    async function patupd(v) {
        form2.setFieldsValue({ svid:v.id, name:v.name, descr:v.descr, text:v.text, text2:v.text2, card:v.card, review:v.review, price: v.price, multi: v.multi, visible: v.visible, url: v.url }); 
    }

    return (
        <React.Fragment>
            <Modal className="modal-mini bg_chester" width={600} title="Добавление услуги" mask={false} visible={props.state.modal === 'services_add'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Добавление услуги</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form onFinish={services_add} form={form1} form1name="form1" layout="vertical">
                            <Form.Item name="name" rules={[{ required: true, message: 'Пожалуйста, укажите название' }]} label="Название" >
                                <Input bordered={false} placeholder="Название" />
                            </Form.Item>
                            <Form.Item name="price" label="Стоимость" >
                                <Input bordered={false} placeholder="Стоимость" />
                            </Form.Item>
                            <Alert style={{marginBottom:24}} message="При указании отрицательной стоимости, услуга будет отображаться как бесплатная" />
                            <Form.Item name="descr" label="Описание">
                                <TextArea bordered={false} placeholder="Описание" maxLength={1000} rows={4} />
                            </Form.Item>
                            <Form.Item name="text" label="Текст напоминания (SMS)" rules={[{ required: true, message: 'Пожалуйста, укажите текст SMS' }]}>
                                <TextArea bordered={false} placeholder="SMS" showCount maxLength={200} rows={4} />
                            </Form.Item>
                            <Form.Item name="text2" label="Текст повторного напоминания (SMS)" rules={[{ required: true, message: 'Пожалуйста, укажите текст SMS' }]}>
                                <TextArea bordered={false} placeholder="SMS" showCount maxLength={200} rows={4} />
                            </Form.Item>
                            <Form.Item hidden name="url" label="Ссылка" >
                                <Input bordered={false} placeholder="https://..." />
                            </Form.Item>
                            <Form.Item name="multi" label="Повтор" rules={[{ required: true, message: 'Пожалуйста, укажите возможность повтора' }]}  >
                                <Select>
                                    <Option value="0">Оказывается один раз</Option>
                                    <Option value="1">Возможен повтор</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="card" label="Видимость в карточке пациента" rules={[{ required: true, message: 'Пожалуйста, укажите статус отображения услуги' }]}  >
                                <Select>
                                    <Option value="0">Не показывать</Option>
                                    <Option value="1">Показывать</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues[`card`] !== currentValues[`card`]}
                            >
                                {({ getFieldValue }) =>
                                    (getFieldValue(`card`) === '1')  ? (
                                    <Form.Item
                                        name={`review`} 
                                        label="Вопрос в карточке"
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea bordered={false} placeholder="Вопрос" showCount maxLength={200} rows={4} />
                                    </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Modal className="modal-mini bg_chester" width={600} title="Обновление услуги" mask={false} visible={props.state.modal === 'services_upd'} onOk={() => props.setState( { modal:'' } )} onCancel={() => {props.setState( { modal:'' } );}} footer={null}>
                <div className="modal-mini-header">
                    <div className="modal-mini-header-title">Обновление услуги</div>
                </div>
                <div className="modal-mini-body">
                    {!props.state.moreload || !props.state.loading ?
                        <Form onFinish={services_upd} form={form2} form1name="form1" layout="vertical">
                            <Form.Item name="name" rules={[{ required: true, message: 'Пожалуйста, укажите название' }]} label="Название" >
                                <Input bordered={false} placeholder="Название" />
                            </Form.Item>
                            <Form.Item name="price" label="Стоимость" >
                                <Input bordered={false} placeholder="Стоимость" />
                            </Form.Item>
                            <Alert style={{marginBottom:24}} message="При указании отрицательной стоимости, услуга будет отображаться как бесплатная" />
                            <Form.Item name="descr" label="Описание">
                                <TextArea bordered={false} placeholder="Описание" maxLength={1000} rows={4} />
                            </Form.Item>
                            <Form.Item name="text" label="Текст напоминания (SMS)" rules={[{ required: true, message: 'Пожалуйста, укажите текст SMS' }]}>
                                <TextArea bordered={false} placeholder="SMS" showCount maxLength={200} rows={4} />
                            </Form.Item>
                            <Form.Item name="text2" label="Текст повторного напоминания (SMS)" rules={[{ required: true, message: 'Пожалуйста, укажите текст SMS' }]}>
                                <TextArea bordered={false} placeholder="SMS" showCount maxLength={200} rows={4} />
                            </Form.Item>
                            <Form.Item hidden name="url" label="Ссылка" >
                                <Input bordered={false} placeholder="https://..." />
                            </Form.Item>
                            <Form.Item hidden name={`svid`} label='ID' rules={[{ required: true, message: `Пожалуйста, введите ID!` }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="multi" label="Повтор" rules={[{ required: true, message: 'Пожалуйста, укажите возможность повтора' }]} >
                                    <Select>
                                    <Option value="0">Оказывается один раз</Option>
                                    <Option value="1">Возможен повтор</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="card" label="Видимость в карточке пациента" rules={[{ required: true, message: 'Пожалуйста, укажите статус отображения услуги' }]}  >
                                <Select>
                                    <Option value="0">Не показывать</Option>
                                    <Option value="1">Показывать</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues[`card`] !== currentValues[`card`]}
                            >
                                {({ getFieldValue }) =>
                                    (getFieldValue(`card`) === '1')  ? (
                                    <Form.Item
                                        name={`review`} 
                                        label="Вопрос в карточке"
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea bordered={false} placeholder="Вопрос" showCount maxLength={200} rows={4} />
                                    </Form.Item>
                                    ) : null
                                }
                            </Form.Item>
                            <Divider />
                            <div className="fcenter">
                                <Form.Item>
                                    <Button className="des-button" type="dashed" shape="round" htmlType="submit">Сохранить</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    :
                        <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                    }
                </div>
            </Modal>
            <Row className="wowload">
                <div className="floattask"><span onClick={() => {props.modal('services_add');form1.resetFields();}}></span></div>
                <Col key="k2_clients" span={24} style={{padding:20}} className="" >
                    { props.state.services.length > 0 ?
                        <Space wrap className="cardlist">
                            {props.state.services.map( list => 
                                <Card 
                                    className="card_no_padding"
                                    bordered={false} 
                                    style={{width:'100%'}}
                                    key={list.id}
                                >
                                    <div className="header_title header_act">
                                        <div>
                                            {list.name}
                                        </div>
                                        <div>
                                            <Tooltip title={`Редактировать`}>
                                                <EditOutlined style={{color:'#ffffff'}} onClick={() => {patupd(list); props.modal('services_upd');}} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className="body_card bg_paws">
                                        <pre className="body_card_text">{list.descr}</pre>
                                        <div className="body_card_price">{list.price > 0 ? list.price+'₽' : (list.price < 0 ? 'Бесплатно' : '')}</div>
                                        <div className="body_card_bottom">
                                            <Tooltip title={Number(list.block) === 0 ? 'Заблокировать' : 'Разблокировать'}>
                                                {Number(list.block) === 0 ?
                                                    <EyeInvisibleOutlined className="vis_but" onClick={() => props.api({ 'method':'services_block', 'svid':`${list.id}` },false)} />
                                                :
                                                    <EyeOutlined className="vis_but" onClick={() => props.api({ 'method':'services_block', 'svid':`${list.id}` },false)} />
                                                }
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Card>
                            )
                            }
                        </Space>
                    :
                        <div className="fcenter"><Empty /></div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;