import React from 'react';
import { Alert, Timeline, Space, Row, Col } from 'antd';
import { SmileOutlined } from '@ant-design/icons';

const Global = props => {

    return (
        <Row className="wowload">
            <Alert
                message="Error"
                description="Instagram servers are not responding. Try again later."
                type="error"
                showIcon
                style={{marginBottom:'20px'}}
            />
            <Col className="shadowscreen" span={24} style={{padding:'20px'}} >
                <Space direction="vertical">
                    <Timeline>
                        <Timeline.Item color="green">Получены данные для запроса</Timeline.Item>
                        <Timeline.Item color="green">Первый запрос сформирован</Timeline.Item>
                        <Timeline.Item color="green">Запрос отправлен</Timeline.Item>
                        <Timeline.Item color="red">
                            <p>Ошибка соединения:</p>
                            <p>Network problem (1 attempt).</p>
                            <p>Network problem (2 attempt).</p>
                            <p>Network problem (3 attempt).</p>
                        </Timeline.Item>
                        <Timeline.Item color="red">Ответ не получен</Timeline.Item>
                        <Timeline.Item color="red">Неудачная авторизация</Timeline.Item>
                    </Timeline>
                </Space>
            </Col>    
        </Row>
    )
};

export default Global;